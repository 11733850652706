import styled from 'styled-components';
import * as palette from '../../styles/variables';

export const FooterSectionContainer = styled.div`
  background: ${palette.PRIMARY_SECTION_BACKGROUND};
  padding: 60px;
  @media only screen and (min-width: 768px) and (max-width: 992px) {
    padding: 45px 50px 80px;
  }
  @media only screen and (min-width: 576px) and (max-width: 768px) {
    padding: 30px 40px 70px;
  }
  @media (max-width: 768px) {
    padding: 30px 20px 70px;
  }
`;

export const SitemapContainer = styled.ul`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
  @media (min-width: 1500px) {
    max-width: 1300px;
    margin: 0 auto;
  }
  @media (max-width: 768px) {
    .logoListItem {
      flex: 1 1 100%;
      margin: 15px;
    }
  }
`;

export const SitemapList = styled.li`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0px 12px;
  @media only screen and (min-width: 768px) and (max-width: 992px) {
    margin: 15px 30px;
  }
  @media only screen and (min-width: 576px) and (max-width: 768px) {
    margin: 20px;
  }
  @media (max-width: 768px) {
    margin: 15px;
    flex: 1 1 30%;
  }
  h5 {
    color: ${palette.APP_BRAND_COLOR};
    margin-bottom: 8px;
    a {
      color: ${palette.APP_BRAND_COLOR};
    }
  }
  .linkItem {
    color: ${palette.APP_BRAND_COLOR};
    font-weight: normal;
    font-size: 14px;
    line-height: 32px;
    display: inline-flex;
    position: relative;
    .logo {
      width: 100px;
    }
  }
  .contactItem {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  @media only screen and (min-width: 992px) and (max-width: 1200px) {
    margin: 0px 18px;
  }
`;

export const ContactIcon = styled.div`
  margin-right: 10px;
  display: flex;
  align-items: center;
`;
