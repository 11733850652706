import { graphql, useStaticQuery, Link } from "gatsby"
import React, { Fragment } from "react"
import Logo from '../../images/logo.png';
import {
  FooterSectionContainer,
  SitemapContainer,
  SitemapList,
  ContactIcon
} from './styles';

export const FooterSection = ({
  sitemapList,
  preview
}) => {

  const contactLink = (info) => {
    if(info.title === 'Mail') {
      return `mailto:${info.link}`
    }
    if(info.title === 'Whatsapp') {
      if(typeof window !== 'undefined' && window.innerWidth < 769) {
        return `whatsapp://send?phone=${info.link}&text=Hello.`
      } else {
        return `https://web.whatsapp.com/send?phone=${info.link}&text=Hello.`
      }
    }
  };

  return (
    <FooterSectionContainer>
      <SitemapContainer>
        {
          typeof window !== 'undefined' && window.location.pathname !== '/sitemap/' &&
          <SitemapList className="logoListItem">
            <Link to="/" className="linkItem">
              <img className="logo" src={Logo} alt="logo" />
            </Link>
          </SitemapList>
        }
        {
          sitemapList && sitemapList.map(dataItem =>
            <SitemapList key={dataItem.id}>
              <h5>{dataItem.title}</h5>
              {
                dataItem.sitemap && dataItem.sitemap.map(sitemap =>
                  dataItem.key === 'contact' ?
                  <Fragment key={sitemap.id}>
                    {
                      <a href={contactLink(sitemap)} target="_blank" rel="noopener noreferrer" key={sitemap.id} className="linkItem">
                        <ContactIcon>
                        {/* {
                          preview ? <img src={sitemap.image} alt={sitemap.title} /> :
                          <Fragment>
                            {
                              (sitemap.image.extension === 'svg' && sitemap.image.childImageSharp === null) ? <img src={sitemap.image.publicURL} alt={sitemap.title} /> : <img src={sitemap.image.childImageSharp.fluid.src} alt={sitemap.title} />
                            }
                          </Fragment>
                        } */}
                        Email:
                        </ContactIcon>
                        {sitemap.link}
                      </a>
                    }
                  </Fragment> :
                  <Link to={sitemap.link} key={sitemap.id} className="linkItem">{sitemap.title}</Link>
                )
              }
            </SitemapList>
          )
        }
      </SitemapContainer>
    </FooterSectionContainer>
  );
};

const Footer = props => {

  const data = useStaticQuery(graphql`
    query {
      file(relativePath: {eq: "sitemap.md"}) {
        childMarkdownRemark {
          frontmatter {
            sitemapList {
              id
              title
              key
              sitemap {
                id
                link
                title
              }
              smlinks {
                id
                link
                title
                image {
                  childImageSharp {
                    fluid {
                      src
                    }
                  }
                  extension
                  publicURL
                }
              }
            }
          }
        }
      }
    }
  `);

  const content = data.file.childMarkdownRemark.frontmatter;

  return (
    <Fragment>
      {
        content &&
          <FooterSection
            sitemapList={content.sitemapList}
            preview={false}
          />
      }
    </Fragment>
  )
}

export default Footer
