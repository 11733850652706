import { Link } from "gatsby"
import React, { Fragment } from "react"
import {
  NavBarContainer,
  NavLinkContainer,
  NavLink
} from './styles';

const NavigationMenu = props => {
  return (
    <Fragment>
      <NavBarContainer >
        <NavLinkContainer>
          <NavLink key="home">
            <Link to="/" activeClassName="activeLink" onClick={props.onClick}>Home</Link>
          </NavLink>
          <NavLink key="services">
            <Link to="/services/" activeClassName="activeLink" onClick={props.onClick}>Services</Link>
          </NavLink>
          <NavLink key="tools">
            <Link to="/tools/" activeClassName="activeLink" onClick={props.onClick}>Tools & Expertise</Link>
          </NavLink>
          <NavLink key="contact">
            <Link to="/contact-us" activeClassName="activeLink" onClick={props.onClick}>Contact Us</Link>
          </NavLink>
        </NavLinkContainer>
      </NavBarContainer>
    </Fragment>
  )
}

export default NavigationMenu
